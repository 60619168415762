import baseAxios from '@/api/base';

// TODO JAMIE
// baseAxios.defaults.baseURL = `${process.env.VUE_APP_APIPATH}`;

/** Video api */
/**
 * 取得(推薦影片(改試聽課程))料表 more
 * @param {object} data {
  "pageIndex": 0,
  "pageSize": 0,
  "data": {}
  }
 */
export const GetHomeVideo = (data) => baseAxios.post('/FrontEnd/HomeVideo/HomeVideo', data);
// 取啟用(DESC)前三的(推薦影片(改試聽課程))料表
export const GetHomeVideoTop3 = () => baseAxios.post('/FrontEnd/HomeVideo/HomeVideoTop3');
