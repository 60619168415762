<template>
  <section class="video container pb-8 ">
    <ul class="row">
      <li class="col-12 col-md-4 mb-3 mb-md-8" v-for="item in PageInfo.data" :key="item.homeLinkId">
        <!-- data-src="https://www.youtube.com/embed/qDO1twqS9eg?rel=0&controls=1&enablejsapi=1&origin=http%3A%2F%2Fkai-tech.com.tw&widgetid=3" -->
        <div
          class="video-btn video__card card card-shadow card-hover-border mb-3 mb-md-0"
          data-toggle="modal"
          data-target="#myModal"
          @click="OpenVideo(item.hasVideo, item.homeLinkUrl, item.homeLinkImgPath)"
        >
          <div class="video__img bg-outer">
            <div
              class="bg-image"
              :style="{
                backgroundImage: 'url(' + item.homeLinkImgPath + ')',
              }"
            ></div>
          </div>
          <div class="card-body p-3 p-md-4">
            <h5 class="card-title">{{ item.homeLinkName }}</h5>
            <div class="d-flex justify-content-end align-items-center">
              <p>試聽去</p>
              <button type="button" class="video-btn btn cs-listen p-2">
                <i class="fa fa-headphones fa-lg" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <!-- 分頁 -->
    <Pagination
      @GetList="GetList"
      :page.sync="Pagination.pageIndex"
      :page-row="Pagination.pageSize"
      :total-count="PageInfo.count"
    />

    <!-- videoModal -->
    <div
      class="modal fade bd-example-modal-xl"
      id="videoModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myExtraLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <!-- 16:9 aspect ratio -->
            <div class="embed-responsive embed-responsive-16by9">
              <video id="player" playsinline controls :data-poster="videoImgSrc">
                <!-- <source
                  v-for="(item, index) in videoSource"
                  :key="index"
                  :size="item.size"
                  :src="item.src"
                /> -->
                <!-- <source
                  size="720"
                  src="https://rp-bucket-streaming.s3-ap-northeast-1.amazonaws.com/freem3u8/Demo/Demo_720p.m3u8"
                />
                <source
                  size="1080"
                  src="https://rp-bucket-streaming.s3-ap-northeast-1.amazonaws.com/freem3u8/Demo/Demo_1080p.m3u8"
                /> -->
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Plyr from 'plyr';
import Hls from 'hls.js';
import 'plyr/dist/plyr.css';
import Pagination from '@/components/Pagination.vue';
import { GetHomeVideo } from '@/api/VideoApi';
/* global $ */
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      player: '',
      currentTime: 0,
      videoImgSrc: '',
      videoSource: [
        {
          src: '_720p.m3u8',
          size: 720,
        },
        {
          src: '_1080.m3u8',
          size: 1080,
        },
      ],
      options: {
        clickToPlay: false,
        controls: [
          'play-large',
          'rewind',
          'play',
          'fast-forward',
          'progress',
          'current-time',
          'duration',
          'mute',
          'volume',
          'settings',
          'fullscreen',
        ],
        // 'pip', 子母畫面
        settings: ['quality', 'speed'],
        quality: { default: 720, options: [1080, 720] },
        speed: { selected: 1, options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2] },
      },
      Pagination: {
        // 有分頁要有此model data
        pageIndex: 1, // 預設從第幾頁開始
        pageSize: 6, // 預設每頁幾筆
        data: {},
      },
      PageInfo: {
        count: 0, // api給的總筆數
        data: [],
      },
    };
  },
  created() {
    this.GetList();
  },
  mounted() {},
  methods: {
    GetList() {
      GetHomeVideo(this.Pagination).then((response) => {
        if (response.data.statusCode === 0) {
          this.PageInfo.count = response.data.count;
          this.PageInfo.data = response.data.data;
          this.PageInfo.data.forEach((item) => {
            // eslint-disable-next-line no-param-reassign
            item.homeLinkUrl = `/freem3u8/${item.homeLinkUrl}/${item.homeLinkUrl}`;
            return item;
          });

          if (this.Pagination.pageIndex !== 1 && this.PageInfo.data.length === 0) {
            this.Pagination.pageIndex = 1;
            this.GetList();
          }
          setTimeout(() => {
            this.SetModal();
          }, 100);
        }
      });
    },
    OpenVideo(hasVideo, url, img) {
      if (hasVideo) {
        this.currentTime = 0;
        this.videoImgSrc = img;
        this.videoSource[0].src = `${url}_720.m3u8`;
        this.videoSource[1].src = `${url}_1080.m3u8`;

        const video = document.querySelector('video');

        // 子母畫面設定
        // function onEnterPip() {
        //   $('#videoModal').modal('hide');
        //   console.log('進入子母畫面');
        // }

        // function onExitPip() {
        //   $('#videoModal').modal({
        //     backdrop: 'static',
        //   });
        //   console.log('離開子母畫面');
        // }
        // video.addEventListener('enterpictureinpicture', onEnterPip, false);
        // video.addEventListener('leavepictureinpicture', onExitPip, false);

        video.dataset.poster = this.videoImgSrc;
        const defaultOptions = this.options;
        if (Hls.isSupported()) {
          const myStorage = window.localStorage;
          // eslint-disable-next-line operator-linebreak
          const quality = JSON.parse(
            myStorage.getItem('plyr') !== null ? myStorage.getItem('plyr') : '{"quality":720}',
          );
          const Level = parseInt(quality.quality, 10) === 720 ? 0 : 1;
          const hls = new Hls({ startLevel: Level });

          hls.loadSource(`${url}.m3u8`);

          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            // Transform available levels into an array of integers (height values).
            const availableQualities = hls.levels.map((l) => l.height);
            // Add new qualities to option
            defaultOptions.quality = {
              default: 720, // availableQualities[0]
              options: availableQualities,
              // this ensures Plyr to use Hls to update quality level
              forced: true,
              onChange: (e) => this.updateQuality(e),
            };
            this.player = new Plyr(video, defaultOptions);
            hls.attachMedia(video);

            // // 切換影片品質事件
            // this.player.on('qualitychange', () => {
            //   console.log('currentTime--------', this.player.currentTime);
            // });
            // 影片載入事件
            this.player.on('loadeddata', () => {
              $('.plyr__poster')
                .unbind()
                .on('click', () => {
                  if (this.player.playing === true) this.player.pause();
                  else this.player.play();
                });
            });
            // // 影片時間
            // this.player.on('timeupdate', () => { // event
            //   this.currentTime = this.player.currentTime;
            // });

            this.player.setXHRWithCredentials(true);
          });

          window.hls = hls;
        } else {
          this.player = new Plyr(video, defaultOptions);
          // 切換影片品質事件
          // this.player.on('qualitychange', () => {
          //   console.log(this.player);
          // });
          // 影片載入事件 (event)
          this.player.on('loadeddata', () => {
            if (this.currentTime !== 0) this.player.currentTime = this.currentTime;
            $('.plyr__poster')
              .unbind()
              .on('click', () => {
                if (this.player.playing === true) this.player.pause();
                else this.player.play();
              });
          });
          // 影片時間 (event)
          this.player.on('timeupdate', () => {
            this.currentTime = this.player.currentTime;
          });

          // 影片來源
          this.player.source = {
            type: 'video',
            sources: [
              {
                src: this.videoSource[0].src,
                size: 720,
              },
              {
                src: this.videoSource[1].src,
                size: 1080,
              },
            ],
            poster: this.videoImgSrc,
          };
          // this.player.setXHRWithCredentials(true);
        }

        $('#videoModal').modal({
          backdrop: 'static',
        });
      } else {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '影片即將上架',
        });
      }
    },
    SetModal() {
      $('#videoModal').on('shown.bs.modal', () => {}); // stop playing the youtube video when I close the modal

      $('#videoModal').on('hide.bs.modal', () => {
        // const video = document.querySelector('video');
        // video.pause();
        this.player.destroy();
      }); // video wrapper
    },
    updateQuality(newQuality) {
      window.hls.levels.forEach((level, levelIndex) => {
        if (level.height === newQuality) {
          // console.log(`Found quality match with ${newQuality}`);
          window.hls.currentLevel = levelIndex;
        }
      });
    },
  },
};
</script>

<style>
html {
  --plyr-color-main: #0096a4;
}
div[role='menu'] {
  max-height: 100px;
  overflow-x: auto;
}
</style>
